import $axios from "../../api";

const module = "attendance"
const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

const state = () => ({
  isLoading: false,
  isErrors: "",
  page: 1,
  isSuccess: false,
  list: null,
  detail: null,
  successDataHris: [],
  successDataFinger: [],
  failedDataHris: [],
  failedDataFinger: [],
  previewData: [],

});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_IS_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_DETAIL(state, payload) {
    state.detail = payload;
  },
  SET_SUCCESS_DATA_HRIS(state, payload) {
    state.successDataHris = payload;
  },
  SET_FAILED_DATA_HRIS(state, payload) {
    state.failedDataHris = payload;
  },
  SET_SUCCESS_DATA_FINGER(state, payload) {
    state.successDataFinger = payload;
  },
  SET_FAILED_DATA_FINGER(state, payload) {
    state.failedDataFinger = payload;
  },
  SET_PREVIEW_DATA(state, payload) {
    state.previewData = payload;
  },

};

const getters = {
  successDataHris: state => state.successDataHris,
  failedDataHris: state => state.failedDataHris,
  successDataFinger: state => state.successDataFinger,
  failedDataFinger: state => state.failedDataFinger,
  previewData: state => state.previewData,
};

const actions = {
  getList({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `${module}/list?page=${state.page}&view=${rootState.Main.view
          }${payload || ""}`
        )
        .then((res) => {
          commit("SET_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getDetail({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`${module}/view${payload}`)
        .then((res) => {
          commit("SET_DETAIL", res.data);
          commit("SET_LOADING", false);
          resolve(res.data);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
          reject(error);
        });
    });
  },
  importHrisData({ commit, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', payload);

      $axios
        .post(`${module}/import-hris`, formData)
        .then((res) => {
          if (res.data.status === 'success') {

            commit("SET_SUCCESS_DATA_HRIS", res.data.data || []);
            commit("SET_FAILED_DATA_HRIS", res.data.failed || []);
            dispatch("Main/globalSuccessSweetAlert",
              'Data imported successfully.',
              { root: true, });
            commit("SET_LOADING", false);
            resolve(res);
          } else {
            commit("ASSIGN_ERRORS", { message: res.data.message });
            dispatch("Main/globalSweetAlert",
              res.data.message,
              { root: true, });
            commit("SET_LOADING", false);
            reject(new Error(res.data.message));
          }
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          commit("ASSIGN_ERRORS", error.response ? error.response.data : { message: 'Unknown error occurred' });
          dispatch("Main/globalSweetAlert",
            error.response ? error.response.data.message : 'An unknown error occurred.',
            { root: true, });
          commit("SET_LOADING", false);
          reject(error);
        });
    });
  },
  downloadHrisFail({ commit, dispatch }) {
    commit('SET_LOADING', true);

    return new Promise((resolve, reject) => {
      $axios.get(`${module}/import-hris/failed`, {
        responseType: 'blob',
        headers: {
          'X-CSRF-TOKEN': csrfToken
        }
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;

          const contentDisposition = response.headers['content-disposition'];
          let fileName = 'failed_hris.xlsx';
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch && fileNameMatch.length === 2) fileName = fileNameMatch[1];
          }

          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          commit('SET_LOADING', false);
          resolve();
        })
        .catch((error) => {
          commit('SET_LOADING', false);
          dispatch('Main/globalSweetAlert', error.response, { root: true });
          reject(error);
        });
    });
  },
  saveHris({ commit, dispatch }) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios.post(`${module}/import-hris/save`)
        .then((res) => {
          // Log untuk debugging
          if (res.data.status === 'success') {
            dispatch("Main/globalSuccessSweetAlert", 'Data imported successfully.', { root: true });
            commit("SET_LOADING", false);
            commit("SET_IS_SUCCESS", true);
            resolve(res);
          } else {
            commit("ASSIGN_ERRORS", { message: res.data.message });
            dispatch("Main/globalSweetAlert", res.data.message, { root: true });
            commit("SET_LOADING", false);
            commit("SET_IS_SUCCESS", false);
            reject(new Error(res.data.message));
          }
        })
        .catch((error) => {
          console.error('Request Error:', error);

          commit("SET_LOADING", false);
          commit("SET_IS_SUCCESS", false);
          commit("ASSIGN_ERRORS", error.response ? error.response.data : { message: 'Unknown error occurred' });
          dispatch("Main/globalSweetAlert", error.response ? error.response.data.message : 'An unknown error occurred.', { root: true });
          reject(error);
        });
    });
  },
  importFingerData({ commit, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', payload);

      $axios
        .post(`${module}/import-finger`, formData)
        .then((res) => {
          if (res.data.status === 'success') {

            commit("SET_SUCCESS_DATA_FINGER", res.data.data || []);
            commit("SET_FAILED_DATA_FINGER", res.data.failed || []);
            dispatch("Main/globalSuccessSweetAlert",
              'Data imported successfully.',
              { root: true, });
            commit("SET_LOADING", false);
            resolve(res);
          } else {
            commit("ASSIGN_ERRORS", { message: res.data.message });
            dispatch("Main/globalSweetAlert",
              res.data.message,
              { root: true, });
            commit("SET_LOADING", false);
            reject(new Error(res.data.message));
          }
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          commit("ASSIGN_ERRORS", error.response ? error.response.data : { message: 'Unknown error occurred' });
          dispatch("Main/globalSweetAlert",
            error.response ? error.response.data.message : 'An unknown error occurred.',
            { root: true, });
          commit("SET_LOADING", false);
          reject(error);
        });
    });
  },
  downloadFingerFail({ commit, dispatch }) {
    commit('SET_LOADING', true);
    return new Promise((resolve, reject) => {
      $axios.get(`${module}/import-finger/failed`, {
        responseType: 'blob',
        headers: {
          'X-CSRF-TOKEN': csrfToken
        }
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;

          const contentDisposition = response.headers['content-disposition'];
          let fileName = 'failed_finger.xlsx';
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch && fileNameMatch.length === 2) fileName = fileNameMatch[1];
          }

          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          commit('SET_LOADING', false);
          resolve();
        })
        .catch((error) => {
          commit('SET_LOADING', false);
          dispatch('Main/globalSweetAlert', error.response, { root: true });
          reject(error);
        });
    });
  },
  saveFinger({ commit, dispatch }) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios.post(`${module}/import-finger/save`)
        .then((res) => {
          // Log untuk debugging

          if (res.data.status === 'success') {
            dispatch("Main/globalSuccessSweetAlert", 'Data imported successfully.', { root: true });
            commit("SET_LOADING", false);
            commit("SET_IS_SUCCESS", true);
            resolve(res);
          } else {
            commit("ASSIGN_ERRORS", { message: res.data.message });
            dispatch("Main/globalSweetAlert", res.data.message, { root: true });
            commit("SET_LOADING", false);
            commit("SET_IS_SUCCESS", false);
            reject(new Error(res.data.message));
          }
        })
        .catch((error) => {
          console.error('Request Error:', error);

          commit("SET_LOADING", false);
          commit("SET_IS_SUCCESS", false);
          commit("ASSIGN_ERRORS", error.response ? error.response.data : { message: 'Unknown error occurred' });
          dispatch("Main/globalSweetAlert", error.response ? error.response.data.message : 'An unknown error occurred.', { root: true });
          reject(error);
        });
    });
  },
  exportIndividualAttendance({ commit, dispatch }, { id, year, month }) {
    commit('SET_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = `${module}/export/individual?id=${id}&date=${year}-${month}-1`;
      $axios.get(url, {
        responseType: 'blob',
        headers: {
          'X-CSRF-TOKEN': csrfToken
        }
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;

          const contentDisposition = response.headers['content-disposition'];
          let fileName = `export-${id}-${year}-${month}-attendance.xlsx`;
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch && fileNameMatch.length === 2) fileName = fileNameMatch[1];
          }

          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          commit('SET_LOADING', false);
          resolve();
        })
        .catch((error) => {
          commit('SET_LOADING', false);
          dispatch('Main/globalSweetAlert', error.response, { root: true });
          reject(error);
        });
    });
  },
  exportConvertExcel({ commit, dispatch }, payload) {
    commit('SET_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = `${module}/export/convert${payload}`;
      $axios.get(url, {
        responseType: 'blob',
        headers: {
          'X-CSRF-TOKEN': csrfToken
        }
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;

          const contentDisposition = response.headers['content-disposition'];
          let fileName = `export-attendance-final.xlsx`;
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch && fileNameMatch.length === 2) fileName = fileNameMatch[1];
          }

          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          commit('SET_LOADING', false);
          resolve();
        })
        .catch((error) => {
          commit('SET_LOADING', false);
          dispatch('Main/globalSweetAlert', error.response, { root: true });
          reject(error);
        });
    });
  },
  exportKerajinanExcel({ commit, dispatch }, payload) {
    commit('SET_LOADING', true);
    return new Promise((resolve, reject) => {
      const url = `${module}/export/kerajinan${payload}`;
      $axios.get(url, {
        responseType: 'blob',
        headers: {
          'X-CSRF-TOKEN': csrfToken
        }
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;

          const contentDisposition = response.headers['content-disposition'];
          let fileName = `export-payroll-kerajinan.xlsx`;
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch && fileNameMatch.length === 2) fileName = fileNameMatch[1];
          }

          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          commit('SET_LOADING', false);
          resolve();
        })
        .catch((error) => {
          commit('SET_LOADING', false);
          dispatch('Main/globalSweetAlert', error.response, { root: true });
          reject(error);
        });
    });
  },
  removeCache({ commit, dispatch }, payload) {
    // commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/remove/cache/${payload}`)
        .then((res) => {
          // commit("SET_LOADING", false);

          if (payload == 'hris') {
            commit("SET_SUCCESS_DATA_HRIS", []);
            commit("SET_FAILED_DATA_HRIS", []);

          } else if (payload == 'finger') {
            commit("SET_SUCCESS_DATA_FINGER", []);
            commit("SET_FAILED_DATA_FINGER", []);

          } else {
            commit("SET_SUCCESS_DATA_HRIS", []);
            commit("SET_FAILED_DATA_HRIS", []);
            commit("SET_SUCCESS_DATA_FINGER", []);
            commit("SET_FAILED_DATA_FINGER", []);
          }

          if (res.data.status === 'success') {
            resolve(res);
          } else {
            commit("ASSIGN_ERRORS", { message: res.data.message });
            reject(new Error(res.data.message));
          }
        })
        .catch((error) => {
          // commit("SET_LOADING", false);
          const errorMsg = error.response ? error.response.data.message : 'An unknown error occurred.';
          commit("ASSIGN_ERRORS", error.response ? error.response.data : { message: errorMsg });
          reject(error);
        });
    });
  },
  deleteAttend({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/delete`, payload)
        .then((response) => {
          dispatch("Main/globalSuccessSweetAlert", 'Data deleted.', { root: true });
          resolve(response);
        })
        .catch((error) => {
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
          reject(error);
        });
    });
  },
  deleteAttendPeriode({ commit, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false);
    commit('SET_LOADING', true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`${module}/delete-periode`, payload)
        .then((response) => {
          commit("SET_IS_SUCCESS", true);
          commit('SET_LOADING', false);
          resolve(response);
        })
        .catch((error) => {
          commit("SET_IS_SUCCESS", false);
          commit('SET_LOADING', false);
          dispatch("Main/globalSweetAlert", error.response, { root: true });
          reject(error);
        });
    });
  },
  getPreview({ commit, dispatch }, payload) {
    commit('SET_LOADING', true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`/attendance/preview-delete`, { params: payload })
        .then((res) => {
          if (res.data.status === 'success') {
            commit('SET_PREVIEW_DATA', res.data.data);
          }
          commit('SET_LOADING', false);
          resolve();
        })
        .catch((error) => {
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
          commit('SET_LOADING', false);
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

