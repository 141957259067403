export default [
  {
    name: "attendance_convert",
    path: "/attendance/convert",
    component: () => import("../pages/hr/convert/index.vue")
  },
  {
    name: "recruitment_list",
    path: "/recruitment",
    component: () => import("../pages/hr/recruitment/index.vue")
  },
  {
    name: "employee_index",
    path: "/employee",
    component: () => import("../pages/hr/employee/index.vue"),
  },
  {
    name: "employee_create",
    path: "/employee/create/:id",
    component: () => import("../pages/hr/employee/manage.vue"),
  },
  {
    name: "employee_update",
    path: "/employee/update/:id",
    component: () => import("../pages/hr/employee/manage.vue"),
  },
  {
    name: "division_index",
    path: "/division",
    component: () => import("../pages/hr/division/index.vue"),
  },
  {
    name: "division_create",
    path: "/division/create",
    component: () => import("../pages/hr/division/index.vue"),
  },
  {
    name: "division_update",
    path: "/division/update/:id",
    component: () => import("../pages/hr/division/index.vue"),
  },
  {
    name: "department_index",
    path: "/department",
    component: () => import("../pages/hr/department/index.vue"),
  },
  {
    name: "department_create",
    path: "/department/create",
    component: () => import("../pages/hr/department/index.vue"),
  },
  {
    name: "department_update",
    path: "/department/update/:id",
    component: () => import("../pages/hr/department/index.vue"),
  },
];
