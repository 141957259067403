export default [
    // ================ Brands ================
    {
        name: "brandw_index",
        path: "/website/brand",
        component: () => import("../pages/website/brands/index.vue"),
    },
    {
        name: "brandw_create",
        path: "/website/brand/create",
        component: () => import("../pages/website/brands/manage.vue"),
    },
    {
        name: "brandw_update",
        path: "/website/brand/update/:id",
        component: () => import("../pages/website/brands/manage.vue"),
    },
    // ================ Store Location ================
    {
        name: "storelocw_index",
        path: "/website/store",
        component: () => import("../pages/website/store/index.vue"),
    },
    {
        name: "storelocw_create",
        path: "/website/store/create",
        component: () => import("../pages/website/store/manage.vue"),
    },
    {
        name: "storelocw_update",
        path: "/website/store/update/:id",
        component: () => import("../pages/website/store/manage.vue"),
    },
    // ================ Member ================
    {
        name: "memberw_index",
        path: "/website/member",
        component: () => import("../pages/website/member/index.vue"),
    },
    {
        name: "memberw_create",
        path: "/website/member/create",
        component: () => import("../pages/website/member/manage.vue"),
    },
    {
        name: "memberw_update",
        path: "/website/member/update/:id",
        component: () => import("../pages/website/member/manage.vue"),
    },
    // ================ Keyword Tag ================
    {
        name: "keywordtagw_index",
        path: "/website/keyword-tag",
        component: () => import("../pages/website/keywordTag/index.vue"),
    },
    {
        name: "keywordtagw_create",
        path: "/website/keyword-tag/create",
        component: () => import("../pages/website/keywordTag/manage.vue"),
    },
    {
        name: "keywordtagw_update",
        path: "/website/keyword-tag/update/:id",
        component: () => import("../pages/website/keywordTag/manage.vue"),
    },
    // ================ Search Bar ================
    // {
    //     name: "storelocw_update",
    //     path: "/website/search-bar-product",
    //     component: () => import("../pages/website/searchBarProduct/index.vue")
    // },
    // {
    //     name: "storelocw_update",
    //     path: "/website/search-bar-product/create",
    //     component: () => import("../pages/website/searchBarProduct/manage.vue")
    // },
    // {
    //     name: "storelocw_update",
    //     path: "/website/search-bar-product/update/:id",
    //     component: () => import("../pages/website/searchBarProduct/manage.vue")
    // },
    // {
    //     name: "storelocw_update",
    //     path: "/website/search-placeholder",
    //     component: () => import("../pages/website/searchPlaceholder/index.vue")
    // },
    // ================ Badge ================
    {
        name: "badge_index",
        path: "/website/badge",
        component: () => import("../pages/website/promoBadge/index.vue"),
    },
    {
        name: "badge_create",
        path: "/website/badge/create",
        component: () => import("../pages/website/promoBadge/manage.vue"),
    },
    {
        name: "badge_update",
        path: "/website/badge/update/:id",
        component: () => import("../pages/website/promoBadge/manage.vue"),
    },
    // ================ Email Queue ================
    {
        name: "emailqueue_index",
        path: "/website/email",
        component: () => import("../pages/website/email/index.vue"),
    },
    {
        name: "emailqueue_campaign",
        path: "/website/email-campaign",
        component: () => import("../pages/website/email/campaign.vue"),
    },
    // ================ Orders ================
    {
        name: "orderw_index",
        path: "/website/orders",
        component: () => import("../pages/website/orders/index.vue"),
    },
    // ================ Product ================
    {
        name: "productw_index",
        path: "/website/product",
        component: () => import("../pages/website/product/index.vue"),
    },
    {
        name: "productw_create",
        path: "/website/product/create",
        component: () => import("../pages/website/product/create.vue"),
    },
    {
        name: "productvariantw_duplicate",
        path: "/website/product/duplicate-variant",
        component: () => import("../pages/website/product/singleVariant.vue"),
    },
    {
        name: "productvariantw_update",
        path: "/website/product/update-variant",
        component: () => import("../pages/website/product/singleVariant.vue"),
    },
    {
        name: "productvariantw_create",
        path: "/website/product/create-variant",
        component: () => import("../pages/website/product/singleVariant.vue"),
    },
    {
        name: "productw_update",
        path: "/website/product/update/:id",
        component: () => import("../pages/website/product/updateProduct.vue"),
    },
    // ================ Promotion ================
    // {
    //     name: "productr_update",
    //     path: "/website/promotion",
    //     component: () => import("../pages/website/promotions/index.vue"),
    // },
    // {
    //     name: "productr_update",
    //     path: "/website/promotion/regular",
    //     component: () => import("../pages/website/promotions/regular.vue"),
    // },
    // {
    //     name: "productr_update",
    //     path: "/website/promotion/coupon",
    //     component: () => import("../pages/website/promotions/coupon.vue"),
    // },
    // {
    //     name: "productr_update",
    //     path: "/website/promotion/free-item",
    //     component: () => import("../pages/website/promotions/freeItem.vue"),
    // },
    // {
    //     name: "productr_update",
    //     path: "/website/promotion/buy-get",
    //     component: () => import("../pages/website/promotions/buyGet.vue"),
    // },
    // {
    //     name: "productr_update",
    //     path: "/website/promotion/shipping",
    //     component: () => import("../pages/website/promotions/shipping.vue"),
    // },
    // {
    //     name: "productr_update",
    //     path: "/website/promotion/cashback",
    //     component: () => import("../pages/website/promotions/cashback.vue"),
    // },
    // {
    //     name: "productr_update",
    //     path: "/website/promotion/min-qty",
    //     component: () => import("../pages/website/promotions/minQty.vue"),
    // },
    // {
    //     name: "productr_update",
    //     path: "/website/promotion/disc-combo",
    //     component: () => import("../pages/website/promotions/discCombo.vue"),
    // },
    // {
    //     name: "productr_update",
    //     path: "/website/promotion/double-disc",
    //     component: () => import("../pages/website/promotions/doubleDisc.vue"),
    // },
    // {
    //     name: "productr_update",
    //     path: "/website/promotion/pwp",
    //     component: () => import("../pages/website/promotions/pwp.vue"),
    // },
    // ================ Shipping Tracking ================
    {
        name: "shippingantrw_index",
        path: "/website/shipping/anteraja",
        component: () => import("../pages/website/shipping/anteraja.vue"),
    },
    {
        name: "shippinggosendw_list",
        path: "/website/shipping/gosend",
        component: () => import("../pages/website/shipping/gosend.vue"),
    },
    {
        name: "shippinglalamovew_index",
        path: "/website/shipping/lalamove",
        component: () => import("../pages/website/shipping/lalamove.vue"),
    },
    // ================ Shipping Tracking ================
    {
        name: "refundw_index",
        path: "/website/orders/refund",
        component: () => import("../pages/website/refund/index.vue"),
    },

    {
        name: "subsproduct_index",
        path: "/website/product-subs",
        component: () => import("../pages/website/subscription/index.vue"),
    },
    {
        name: "subsproduct_create",
        path: "/website/product-subs/create",
        component: () => import("../pages/website/subscription/manage.vue"),
    },
    {
        name: "subsproduct_update",
        path: "/website/product-subs/update/:id",
        component: () => import("../pages/website/subscription/manage.vue"),
    },
    {
        name: "subsorder_index",
        path: "/website/orders-subs",
        component: () => import("../pages/website/subscription/orders/index.vue"),
    },
];
